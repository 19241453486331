import { FunctionComponent } from "react";
import styles from "./Desktop1.module.css";

const Desktop1: FunctionComponent = () => {
  return (
    <div className={styles.desktop1}>
      <p className={styles.cAspnetSqlContainer}>
        <span className={styles.c}>C#</span>
        <span className={styles.c}>ASP.NET</span>
        <span className={styles.c}>SQL</span>
      </p>
      <p className={styles.reactAngularAzureContainer}>
        <span className={styles.c}>React</span>
        <span className={styles.c}>Angular</span>
        <span className={styles.c}>Azure</span>
      </p>
      <h1 className={styles.heyImBrendan}>Hey, I’m Brendan Roddy.</h1>
      <p className={styles.imAFullStackContainer}>
        <span className={styles.c}>
          <span className={styles.imA}>{`I’m a `}</span>
          <b className={styles.imA}>full-stack developer</b>
          <span>
            {" "}
            with 3+ years of consulting experience, mostly with clients in
            Chicago.
          </span>
        </span>
        <span className={styles.c}>
          <span>&nbsp;</span>
        </span>
        <span className={styles.c}>
          <span>
            I’m proud of my experience in every phase of app production-- from
            planning and requirements gathering to development, testing, and
            allocating architecture in the cloud.
          </span>
        </span>
        <span className={styles.c}>
          <span>&nbsp;</span>
        </span>
      </p>
      <div className={styles.heresSomeOfContainer}>
        <p className={styles.heresSomeOfMyRecentWork}>
          <span>{`Here’s some of my recent `}</span>
          <b className={styles.imA}>work experience:</b>
        </p>
        <p className={styles.heresSomeOfMyRecentWork}>&nbsp;</p>
        <p className={styles.heresSomeOfMyRecentWork}>&nbsp;</p>
        <p className={styles.heresSomeOfMyRecentWork}>&nbsp;</p>
      </div>
      <p className={styles.healthProfessionalAssociatioContainer}>
        <span className={styles.c}>
          <b>[Health Professional Association] with Capgemini</b>
        </span>
        <span className={styles.c}>
          <b>&nbsp;</b>
        </span>
        <ul className={styles.maintainedAndAddedFeatures}>
          <li className={styles.createdANewVersionOfCusto}>
            <span>
              Maintained and added features to 5 different Angular and React
              applications
            </span>
          </li>
          <li className={styles.createdANewVersionOfCusto}>
            <span>
              Created a new version of customer-facing membership registration
              and payment app, crucial to a $50MM/year revenue center
            </span>
          </li>
          <li className={styles.createdANewVersionOfCusto}>
            <span>
              Coordinated team of 6 engineers to migrate public-facing
              professional directory web application from on-premises servers to
              Microsoft Azure
            </span>
          </li>
          <li className={styles.createdANewVersionOfCusto}>
            <span>
              Maintained and improved Azure infrastructure, including
              implementing security changes that saved client $10,000 monthly in
              damages from bots
            </span>
          </li>
        </ul>
        <span className={styles.c}>
          <span>&nbsp;</span>
        </span>
        <span className={styles.c}>
          <b>&nbsp;</b>
        </span>
        <span className={styles.c}>
          <b>[National Bookstore Chain] with Capgemini</b>
        </span>
        <span className={styles.c}>
          <b>&nbsp;</b>
        </span>
        <ul className={styles.maintainedAndAddedFeatures}>
          <li className={styles.createdANewVersionOfCusto}>
            <span>
              Worked with sales team and practice manager to help develop a
              React proof-of-concept e-commerce application which resulted in a
              six-figure consulting contract in only a week
            </span>
          </li>
          <li className={styles.createdANewVersionOfCusto}>
            <span>
              Rapidly reimplemented proof of concept as an Angular application
              following changing architecture requirements and launched
              application on a tight deadline to meet fall semester
              back-to-school demand
            </span>
          </li>
        </ul>
        <span className={styles.c}>
          <span>&nbsp;</span>
        </span>
        <span className={styles.c}>
          <span>&nbsp;</span>
        </span>
      </p>
      <p className={styles.october2020}>October 2020 - Present</p>
      <p className={styles.juneSeptember}>June - September 2020</p>
      <p className={styles.iveBeenCodingContainer}>
        <span>{`I’ve been coding in my spare time since high school, though, and I love experimenting with new tools.  Here are some `}</span>
        <b>personal projects</b>
        <span>:</span>
      </p>
      <p className={styles.iTendToContainer}>
        <span>{`I tend to use these `}</span>
        <b>technologies</b>
        <span>:</span>
      </p>
    </div>
  );
};

export default Desktop1;
